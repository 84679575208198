.advance_search_mobile {
  background-color: rgb(140, 82, 255);
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-radius: 10px;
  padding: 12px 10px;
}

.custom_class_main_sec {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}


.PriceFilterPlain_filterLabel__Do01J {
  display: none;
}


.custom_class_main_sec .advanceSearch {
  width: 100%;
  display: flex;
  max-width: 250px;
  justify-content: space-evenly;
  flex: 0 0 250px;
}

.custom_class_main_sec .main_container .button_container form.advance_custom_form {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.custom_class_main_sec .main_container {
  min-width: 350px;
  width: 100%;
  max-width: 350px;
  text-align: center;
}

.custom_class_main_sec .main_container .button_container form.advance_custom_form .PriceFilterPlain_filterLabelSelected__2-v5U {
  display: none;
}

.advanceSearch .main_container form {
  grid-column-gap: unset;
  row-gap: unset !important;
}

.advanceSearch .main_container form .search-headingg {
  font-size: 29px;
  text-align: initial;
  width: 100%;
  color: #000;
  font-weight: 700;
  margin-bottom: 5px;
}

.advanceSearch .main_container form .d_flex {
  flex-direction: column;
  justify-content: inherit;
  text-align: initial;
}

.advanceSearch .main_container form .Select_Services {
  padding-top: 20px;
  color: #000;
  font-size: 18px;
  text-align: initial;
}

.advanceSearch .main_container form input {
  background: #dddd;
  padding: 10px;
  border-radius: 5px;
  line-height: normal;
}

.advanceSearch .main_container form .main_homepage_section .MuiBox-root {
  flex-direction: column;
  text-align: initial;
}

.advanceSearch .main_container form .main_homepage_section .w_100 {
  margin: 0;
}

.advanceSearch .main_container form .date_section .MuiBox-root {
  margin: 0;
}

.advanceSearch .main_container form .date_section .MuiBox-root .Select_Services {
  text-align: initial;
}

.advanceSearch .main_container form .d_flex .mainSearch {
  margin: 0;
}

.advanceSearch .main_container form select {
  background: #ddd;
  border: 0;
  color: #000;
}

.advanceSearch .main_container form .date_section .MuiGrid-root {
  background: #ddd;
  border: 0;
  min-height: 72px;
  border-radius: 5px;
}

.advanceSearch .main_container form .date_section .MuiGrid-root input.MuiInputBase-input {
  padding: 5px 0 0;
}

.advanceSearch .main_container form .date_section .MuiGrid-root input::placeholder {
  color: #000;
}

.price_filter .PriceFilterPlain_root__1cU10 {
  border: 1px solid #ddd;
}

.price_filter .PriceFilterPlain_root__1cU10 .PriceFilterPlain_formWrapper__28a4H {
  padding: 0 10px;
}

.price_filter .PriceFilterPlain_root__1cU10 .PriceFilterPlain_formWrapper__28a4H input {
  padding: 0;
}

.advanceSearch .main_container {
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.advanceSearch .main_container .button_container form {
  width: 100%;
}

.advanceSearch .main_container .button_container {
  width: 100%;
  max-width: 100%;
}

/* .searchListingsPanel_new .custom_listing_css {
  width: 100%;
} */

.clear_search {
  background: #ddd;
  color: #000000b3;
  font-size: 22px;
  border-radius: 6px;
}

/* .searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon {
  display: flex;
  padding: 11px 0;
} */

.searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon svg.MuiSvgIcon-root {
  margin-top: 7px;
  margin-right: 7px;
}

.custom_class_main_sec .main_container .button_container form .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0;
  pointer-events: none;
}

.custom_class_main_sec .MuiFormControl-marginNormal {
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0;
}

.custom_class_main_sec .MuiFormControl-marginNormal .MuiInputBase-root.MuiInput-root {
  width: 100%;
  padding: 0 10px;
  border: 0;
}

/* 19-05-2022 */
.Advance_Botton_button .advance_search_button {
  margin-top: 0;
}

.music_btn_name_listing_card:hover {
  background: #651af9;
  color: #fff;
}

.music_btn_name_listing_card {
  transition: 0.3s ease all;
}

@media (max-width: 1439px) {
  .searchListingsPanel_new .custom_listing_css {
    max-width: 95% !important;
  }
}

@media (max-width: 1365px) {
  .searchListingsPanel_new .custom_listing_css .ViewListion_class_div button.button_listing_classs {
    margin-left: 0 !important;
    line-height: normal;
    white-space: nowrap;
  }
}

@media (max-width: 1199px) {
  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    flex-direction: column;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_threeToTwoWrapper__27YXq {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (max-width: 991px) {
  .custom_class_main_sec {
    flex-direction: column;
  }

  .advanceSearch .main_container {
    margin: 0;
  }

  .custom_class_main_sec .advanceSearch {
    max-width: calc(100% - 30px);
    justify-content: space-evenly;
    margin: 0 auto;
  }

  .searchListingsPanel_new .custom_listing_css {
    max-width: calc(100% - 30px) !important;
    padding: 30px 0 0;
  }
}

@media (max-width: 767px) {
  button.advance_search_mobile.advance_search_mobile_close {
    font-size: 17px;
  }

  .custom_class_main_sec .advanceSearch input {
    width: 100% !important;
    max-width: 100% !important;
  }

  .advanceSearch .main_container form select {
    width: 100% !important;
    max-width: 100%;
  }

  .advanceSearch .main_container form .main_homepage_section .w_100 {
    width: 100%;
  }

  .advanceSearch .main_container form .date_section .MuiBox-root {
    width: 100%;
  }

  .advanceSearch .main_container form .date_section .MuiGrid-root {
    min-height: 55px;
  }

  .advanceSearch .main_container form .d_flex .mainSearch {
    width: 100%;
  }

  .advanceSearch .main_container {
    padding: 0;
  }

  .advanceSearch .main_container form .search-headingg {
    padding-top: 20px;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_info__3upOW.custom_mainnn {
    flex-direction: column;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    position: relative;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .review_details {
    padding-top: 30px;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_info__3upOW.custom_mainnn button.button_listing_classs {
    margin: 0px !important;
    position: absolute;
    bottom: 90px;
    right: 20px;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_threeToTwoWrapper__27YXq {
    flex: 0 0 135px;
    max-width: 135px;
  }
}

@media (max-width: 480px) {
  .advanceSearch .main_container {
    border: 0px solid #ddd;
  }
}

.advanceSearch .multiselect-container {
  padding: 0;
}

.advanceSearch .main_container form .main_homepage_section .w_100 {
  margin: 0;
  width: 100%;
  max-width: 100%;
  display: block;
}

.advanceSearch .multiselect-container .search-wrapper.searchWrapper input {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-align: left;
}

.advanceSearch .search-wrapper.searchWrapper {
  border: 0;
  height: 80px;
  overflow: auto;
}

.search-wrapper.searchWrapper span.chip.false {
   /* background: #fff !important;  */
}

.highlightOption {
  background: #906efa;
  color: #fff;
}

.advance_search_mobile {
  background-color: rgb(140, 82, 255);
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-radius: 10px;
  padding: 15px;
  border-radius: 50%;
  border: 0;
  box-shadow: 0 0 4px #ddd;
}

button.advance_search_mobile.advance_search_mobile_close {
  border-radius: 10px;
}

.multiSelectContainer ul li.option:hover {
  background: #906efa;
}

/* 29-04-2022 */
.custom_title h2 {
  margin: 0;
}

.advanceSearch .main_container form .search-headingg { 
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
}

/* 01/05/2022 */
.sort_component button+div {
  background: #fff;
  z-index: 9;
}

@media (min-width: 768px) {
  .custom_class_main_sec {
    padding: 0;
  }
  .star_icon_custom svg.MuiSvgIcon-root {
    width: 45px;
    height: 45px;
}
.custom_image {
  padding-bottom: 80%;
}
/* .custom_image img {
  border-radius: 10px;
} */
}
