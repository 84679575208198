.servicesstyle{
  font-weight: var(--fontWeightSemiBold);
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  
}
.action_container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.action_container button:first-child {
  text-align: left;
}
.action_container button:last-child {
  text-align: right;
}
.optionListContainer li {
  font-size: 14px;
  padding: 6px !important;
}
.selctcity {
    max-width: 300px;
    position: absolute;
    top: 123px;
    background: #fff;
    z-index: 1;
    box-shadow: 0 0 5px #ddd;
    left: 280px;
    padding: 20px;
  }
  
  .searchWrapper {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 0 10px !important;
  }
  
  #makegsn_input  {
    border-bottom: 1px solid var(--marketplaceColor) !important;
    font-size: 15px;
  } 
  
  
  .searchWrapper {
    border: 0;
    padding: 0;
    min-height: 20px !important;
  }
  
  .button {
    border: 0;
    color: var(--marketplaceColor);
    width: 100%;
    margin-left: auto;
    margin-right: 0;
    text-align: end;
  }
  
  .FilterPopup_root__1Xfkn {
    border: 0;
  }
  
  .FilterPopup_root__1Xfkn1:focus {
    outline: none !important;
    border: 0 !important;
  }
  
  .FilterPopup_root__1Xfkn1 {
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .FilterPopup_root__1Xfkn1:hover {
      border: 1px solid #ccc;
      box-shadow: 0 0 6px #dddd;
  }