.MuiRating-iconFilled {
  color: rgb(86, 21, 214) !important;
}

.custom_class_main_sec {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.PriceFilterPlain_filterLabel__Do01J {
  display: none;
}

.custom_class_main_sec .advanceSearch {
  width: 100%;
  display: flex;
  max-width: 250px;
  justify-content: space-evenly;
  flex: 0 0 250px;
}

.custom_class_main_sec .main_container .button_container form.advance_custom_form {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.custom_class_main_sec .main_container {
  min-width: 350px;
  width: 100%;
  max-width: 350px;
  text-align: center;
}

.custom_class_main_sec .main_container .button_container form.advance_custom_form .PriceFilterPlain_filterLabelSelected__2-v5U {
  display: none;
}

.advanceSearch .main_container form {
  grid-column-gap: unset;
  row-gap: unset !important;
}

.advanceSearch .main_container form .search-headingg {
  font-size: 29px;
  text-align: initial;
  width: 100%;
  color: #000;
  font-weight: 700;
  margin-bottom: 5px;
}

.advanceSearch .main_container form .d_flex {
  flex-direction: column;
  justify-content: inherit;
  text-align: initial;
}

.advanceSearch .main_container form .Select_Services {
  padding-top: 20px;
  color: #000;
  font-size: 18px;
  text-align: initial;
}


.advanceSearch .main_container form .main_homepage_section .MuiBox-root {
  flex-direction: column;
  text-align: initial;
}

.advanceSearch .main_container form .main_homepage_section .w_100 {
  margin: 0;
}

.advanceSearch .main_container form .date_section .MuiBox-root {
  margin: 0;
}

.advanceSearch .main_container form .date_section .MuiBox-root .Select_Services {
  text-align: initial;
}

.advanceSearch .main_container form .d_flex .mainSearch {
  margin: 0;
}

.advanceSearch .main_container form select {
  background: #ddd;
  border: 0;
  color: #000;
}

.advanceSearch .main_container form .date_section .MuiGrid-root {
  background: #ddd;
  border: 0;
  min-height: 72px;
  border-radius: 5px;
}

.advanceSearch .main_container form .date_section .MuiGrid-root input.MuiInputBase-input {
  padding: 5px 0 0;
}

.advanceSearch .main_container form .date_section .MuiGrid-root input::placeholder {
  color: #000;
}

.price_filter .PriceFilterPlain_root__1cU10 {
  border: 1px solid #ddd;
}

.price_filter .PriceFilterPlain_root__1cU10 .PriceFilterPlain_formWrapper__28a4H {
  padding: 0 10px;
}

.price_filter .PriceFilterPlain_root__1cU10 .PriceFilterPlain_formWrapper__28a4H input {
  padding: 0;
}

.advanceSearch .main_container form button.advance_search_button:last-child {
  margin: 20px 0 0;
}

.advanceSearch .main_container form button.advance_search_button {
  width: 100%;
  transition: 0.3s ease all;
}

.advanceSearch .main_container {
  border: 1px solid #ddd;
  padding: 20px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.advanceSearch .main_container .button_container form {
  width: 100%;
}

.advanceSearch .main_container form button.advance_search_button:first-child:hover {
  color: #fff;
}

.advanceSearch .main_container .button_container {
  width: 100%;
  max-width: 100%;
}

.clear_search {
  background: #ddd;
  color: #000000b3;
  font-size: 22px;
  border-radius: 6px;
}

.searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon {
  display: flex;
  padding: 0 0 10px 0;
  line-height: normal;
}

.searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon svg.MuiSvgIcon-root {
  margin-top: 7px;
  margin-right: 7px;
}

.custom_class_main_sec .main_container .button_container form .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 0;
  pointer-events: none;
}

.custom_class_main_sec .MuiFormControl-marginNormal {
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0;
}

.custom_class_main_sec .MuiFormControl-marginNormal .MuiInputBase-root.MuiInput-root {
  width: 100%;
  padding: 0 10px;
  border: 0;
}

/* 19-05-2022 */
.main_container form .date_section .MuiInputAdornment-positionEnd {
  display: none;
}

input#search_input::placeholder {
  color: #000;
}

.main_homepage_section .MuiBox-root select {
  padding: 10px;
}

@media (max-width: 1439px) {
  .searchListingsPanel_new .custom_listing_css {
    max-width: 95% !important;
  }
}

@media (max-width: 1365px) {
  .searchListingsPanel_new .custom_listing_css .ViewListion_class_div button.button_listing_classs {
    margin-left: 0 !important;
    line-height: normal;
    white-space: nowrap;
  }
}

@media (max-width: 1199px) {
  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    flex-direction: column;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_threeToTwoWrapper__27YXq {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (max-width: 991px) {
  .custom_class_main_sec {
    flex-direction: column;
  }

  .advanceSearch .main_container {
    margin: 0;
  }

  .custom_class_main_sec .advanceSearch {
    max-width: calc(100% - 30px);
    justify-content: space-evenly;
    margin: 0 auto;
  } 
  .searchListingsPanel_new .custom_listing_css {
    max-width: calc(100% - 30px) !important;
    padding: 30px 0 0;
    width: 100%;
    margin: 0 auto;
}
}

@media (max-width: 767px) {
  .custom_class_main_sec .advanceSearch input {
    width: 100% !important;
    max-width: 100% !important;
  }

  .advanceSearch .main_container form select {
    width: 100% !important;
    max-width: 100%;
  }

  .advanceSearch .main_container form .main_homepage_section .w_100 {
    width: 100%;
  }

  .advanceSearch .main_container form .date_section .MuiBox-root {
    width: 100%;
  }

  .advanceSearch .main_container form .date_section .MuiGrid-root {
    min-height: 55px;
  }

  .advanceSearch .main_container form .d_flex .mainSearch {
    width: 100%;
  }

  .advanceSearch .main_container {
    padding: 0;
  }

  .advanceSearch .main_container form .search-headingg {
    padding-top: 20px;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_info__3upOW.custom_mainnn {
    flex-direction: column;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    position: relative;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .review_details {
    padding-top: 30px;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_info__3upOW.custom_mainnn button.button_listing_classs {
    margin: 0px !important;
    position: absolute;
    bottom: 90px;
    right: 20px;
  }
}

@media (max-width: 480px) {
  .advanceSearch .main_container {
    border: 0px solid #ddd;
  }
}

.advanceSearch .multiselect-container {
  padding: 0;
}

.advanceSearch .main_container form .main_homepage_section .w_100 {
  margin: 0;
  width: 100%;
  max-width: 100%;
  display: block;
}

.advanceSearch .multiselect-container .search-wrapper.searchWrapper input {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 44px !important;
  min-height: 44px !important;
}

.advanceSearch .search-wrapper.searchWrapper {
  border: 0;
  height: 80px;
  overflow: auto;
}

.search-wrapper.searchWrapper span.chip.false {
  background: #906efa;
}

.highlightOption {
  background: #906efa;
  color: #fff;
}

.advance_search_mobile {
  background-color: rgb(140, 82, 255);
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-radius: 10px;
  padding: 15px;
  border-radius: 50%;
  border: 0;
  box-shadow: 0 0 4px #ddd;
}

/* 26-04-2022 */
.custome_price {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.custome_price>div {
  margin-right: 10px;
}

.ListingCard_title__3y9wD.custom_title:hover {
  color: #906efa !important;
}

.custom_main_child_1 .time_cons {
  align-items: center;
  padding-bottom: 10px;
}

.advanceSearch .search-wrapper.searchWrapper {
  height: auto !important;
  min-height: 100px !important;
  /*use of important to overide css */
  float: left;
  width: 100%;
}

.advanceSearch .main_container form select {
  border-radius: 5px;
}

.advanceSearch .search-wrapper.searchWrapper input {
  min-height: 70px;
  color: #000;
}

/* 24-05-2022 */

.topbar_search_hero .MuiGrid-justify-content-xs-space-around,
.topbar_search_hero .main_homepage_section .MuiBox-root select,
.topbar_search_hero div.custom_home_ser div#multiselectContainerReact {
  top: 0;
  border: none;
  height: 100%;
}

.topbar_search_hero .d_flex>div {
  max-width: calc(100%/2 - 10px);
  flex: calc(100%/2 - 10px);
  width: 100%;
}

.topbar_search_hero .MuiInput-underline:after {
  display: none;
}

.topbar_search_heroContainer .main_container .button_container form>* {
  max-width: calc(100%/3);
  flex: calc(100%/3);
  width: 100%;
}

.topbar_search_heroContainer .main_container .button_container form {
  width: 100%;
  max-width: 100%;
  flex: 100%;
}

.topbar_search_heroContainer .main_container .button_container {
  width: 100%;
}



/* 27-04-2022 */
@media (min-width: 768px) {
  .searchListingsPanel_new .custom_listing_css .ViewListion_class_div button.button_listing_classs {
    border-radius: 5px !important;
    cursor: pointer;
  }

  .ListingCard_info__3upOW.custom_main {
    position: relative;
  }

  .ListingCard_priceValue__3FOmG.custome_price.custom_price_mobile {
    position: unset;
    right: 0;
    top: 0;margin-left: 10px;
    font-weight: 500;
    color: var(--marketplaceColor);
    font-size: 16px;
  }

  .custom_main_child_1 *:not(.custom_title, .custom_title *),
  .custom_listing_css .custom_main .time_cons {
    font-weight: 300;
    /* color: #2f3033; */
  }

  .custom_price_mobile {
    margin-top: 10px;
  }

  .custom_main svg.MuiSvgIcon-root {
    /* fill: #8c52ff; */
    width: 18px;
  }

  .custom_title {
    display: flex;
    margin-bottom: 10px;
    color: #000; 
    align-items: center;
    padding-right: 80px; 
  }

  .custom_main_child_1 * span.csnt-bold {
    font-weight: 700;
    margin: 0px 3px 0 5px;
  }

  .ListingCard_priceValue__3FOmG.custome_price.custom_price_mobile>div {
    display: contents;
  }

  .ListingCard_title__3y9wD.custom_title:hover h2 {
    color: var(--marketplaceColor) !important;
  }

  .ListingCard_title__3y9wD.custom_title h2 {
    
    font-size: 20px;
    margin: 0 !important;
  }

  /* .custom_listing_css .SearchResultsPanel_listingCard__3hmi9 {
    box-shadow: none;
  } */

  .custom_listing_css .SearchResultsPanel_listingCard__3hmi9:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .musicstyle_button:empty{display: none;}
}

/* media query start */

@media (min-width: 1200px) {
  div.custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    min-height: 0 !important;
    margin: 0 !important;
  }

  .TopbarSearchFormHome_mainserch__I3jSm.custom_home_ser div#multiselectContainerReact {
    height: 70px;
    top: -2px;
  }

  .search-wrapper.searchWrapper input {
    min-height: auto;
  }

  div.custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    min-height: 0 !important;
    margin: 0 !important;
    align-items: center;
    display: flex;
    height: 100%;
  }
}

/* 24-05-2022 */


@media (max-width: 1365px) {
  .searchListingsPanel_new .custom_listing_css .ViewListion_class_div button.button_listing_classs {
    white-space: unset;
    font-size: 12px;
    letter-spacing: normal;
  }

  .custom_listing_css div.SearchResultsPanel_listingCard__3hmi9 {
    padding: 20px;margin-bottom: 20px;
  }

  .ListingPage_reviewsHeading__3zmuY,
  .searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon,
  .custom_listing_css .custom_main .time_cons,
  .custome_price {
    font-size: 14px;
    line-height: 1.5;
  }
}

@media (max-width: 1199.98px) {
  .custom_listing_css .SearchResultsPanel_listingCard__3hmi9 .custom_main {
    padding-left: 30px;
  }

  div.searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    flex-direction: unset;
  }

  .custom_listing_css .SearchResultsPanel_listingCard__3hmi9 .custom_main .custom_main_child_1 {
    flex: 100% !important;
    max-width: 100% !important;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .custom_listing_css .SearchResultsPanel_listingCard__3hmi9 .custom_main {
    padding-left: 0px;
    flex: 100% !important;
    max-width: 100% !important;
    width: 100%;
  }

  .custom_listing_css .SearchResultsPanel_listingCard__3hmi9 .custom_main .custom_main_child_1 {
    padding-left: 145px;
  }

  div.searchListingsPanel_new .custom_listing_css .ViewListion_class_div button.button_listing_classs {
    border-radius: 5px !important;
    font-size: 16px;
    font-weight: 600 !important;
  }

  /* .ListingPage_reviewsHeading__3zmuY,
  .searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon,
  .custom_listing_css .custom_main .time_cons,
  .custome_price {
    align-items: center;
  } */

  .SearchResultsPanel_listingCards__3WHJi.custom_listing_css .custom_listing_css_child {
    padding: 10px 0 20px !important;
    margin-bottom: 10px !important;
  }

  .ListingPage_reviewsHeading__3zmuY {
    margin: 0;
  }

  div.searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    margin: 0;
    box-shadow: none;
    padding: 0;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_threeToTwoWrapper__27YXq {
    left: 5px;
    position: absolute;
  }

  .ListingPage_sectionReviews__1MGWl {
    width: 100%;
    text-align: center;
  }

  div.searchListingsPanel_new .custom_listing_css .custom_listing_css_child:not(:last-child) {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
  }

  .TopbarSearchFormHome_servicecss__UiQ_Z {
    border-radius: 0;
    border-bottom: 1px solid #ddd;
  }

  .button_container {
    padding: 0 20px;
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }

  button.TopbarSearchFormHome_heroButton__3dJS0 {
    font-size: 16px;
  }

  .custom_home_ser .multiselect-container {
    border: none;
  }

  .search-wrapper.searchWrapper {
    margin-bottom: 0;
  }

  .button_container form .d_flex:nth-child(2)>div {
    width: 100%;
    margin-bottom: 15px;
    height: 60px;
  }

  .d_flex .MuiBox-root.MuiBox-root-3.TopbarSearchFormHome_select_date__1rQoJ {
    margin-bottom: 0;
  }

  .button_container form .d_flex:nth-child(2)>div,
  .button_container form .d_flex:nth-child(2)>div * {
    overflow-y: visible !important;
  }

  .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal .MuiInput-underline:before {
    border-bottom: 1px solid #ddd;
    bottom: -10px;
  }

  .custom_home_ser {
    min-height: 50px;
  }

  .custom_home_ser {
    border-bottom: 1px solid #ddd;
  }

  button.TopbarSearchFormHome_heroButton__3dJS0.clear_search {
    margin-bottom: 20px;
  }

  .TopbarSearchFormHome_mainserch__I3jSm.custom_home_ser {
    height: 100px !important;
  }

  .TopbarSearchFormHome_servicecss__UiQ_Z {
    height: 55px;
    font-size: 16px;
    padding: 0 10px;
    font-weight: 400;
  }
}

/* for show serch buton */
.advanceSearch .main_container .button_container {
  min-height: 120%;
}

.advanceSearch .main_container {
  overflow: scroll;
}

/* banneer section */
/* 24-05-2022 */
.topbar_search_hero .button_container .d-flex>div {
  max-width: calc(100%/3);
  flex: 100%;
  width: 100%;
}
.sort_component {
  margin-top: 20px;
}


@media (max-width: 767px){
  .custom_title .star_icon_custom {
    margin-left: 0;
}

.custom_title .star_icon_custom svg {
    width: 18px;
}
.custom_listing_css_child {
  padding: 0 15px;
}

.custom_listing_css_child button.button_listing_classs {
  margin-bottom: 0;
}

div.searchListingsPanel_new >.custom_listing_css  .custom_listing_css_child {
  padding: 0 5px !important;
}

.searchListingsPanel_new .custom_listing_css .ViewListion_class_div button.button_listing_classs {
  margin-bottom: 0 !important;
}
}
